import React from 'react';
import { connect } from 'react-redux';
import { changePassword } from '../../redux/actions/auth/changePassword';
import {
    Row, Col, Form, Button, Input, message
} from 'antd';

const layout = {
    wrapperCol: { span: 12 },
};

class ChangePassword extends React.Component {
    constructor(props) {
        super(props);
        this.form = React.createRef();
    }
    onFinish = async (values) => {
        await this.props.onChange(values.oldPassword,values.newPassword);
        if(this.props.message){
            message.success(this.props.message);
            this.form.current.resetFields();
        }
        if(this.props.error){
            message.error("Invalid Old Password");
        }
    }
    onFinishFailed = (errors) => {
        console.log(errors);
    }

    render() {
        return <Row>
            <Col span={24}>
                <Form
                    layout="vertical"
                    key="sm"
                    ref={this.form}
                    {...layout}
                    loading={this.props.loading}
                    name="basic"
                    onFinish={this.onFinish}
                    onFinishFailed={this.onFinishFailed}
                >
                    <Form.Item
                        name="oldPassword"
                        label="Old Password"
                        hasFeedback
                        rules={[
                            {
                                required:true,
                                message:'Please input current password'
                            }
                        ]}
                    >
                        <Input.Password />
                    </Form.Item>
                    <Form.Item
                        name="newPassword"
                        label="New Password"
                        rules={[
                            {
                                required: true,
                                message: 'Please input new password!',
                            },
                        ]}
                        hasFeedback
                    >
                        <Input.Password />
                    </Form.Item>

                    <Form.Item
                        name="confirm"
                        label="Confirm New Password"
                        dependencies={['newPassword']}
                        hasFeedback
                        rules={[
                            {
                                required: true,
                                message: 'Please confirm new password!',
                            },
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    if (!value || getFieldValue('newPassword') === value) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(new Error('The two passwords that you entered do not match!'));
                                },
                            }),
                        ]}
                    >
                        <Input.Password />
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit" loading={this.props.loading}>
                            Change
                        </Button>
                    </Form.Item>
                </Form>
            </Col>
        </Row>
    }
}

const mapStateToProps = state => ({
    message: state.auth.message,
    error: state.auth.error,
    loading: state.auth.loading
});

const mapDispatchToProps = dispatch => ({
    onChange: (oldPassword, newPassword) => dispatch(changePassword(oldPassword, newPassword))
})

export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword);