import PaginateNumber from "./PaginateNumber";
import PropTypes from 'prop-types';
import {useState} from 'react';
export default function Pagination({
    numbers,
    onChange
}){
    const [selectedKey,setSelectedKey]=useState(0);
    const paginateNumbers=()=>{
        let paginations=[];
        for(let i=0;i<numbers;i++)
            {
                paginations.push(
                    <PaginateNumber
                        selected={i===selectedKey}
                        onClick={
                            ()=>{
                                setSelectedKey(i)
                                onChange(i+1)
                            }
                        }
                    >
                        {i}
                    </PaginateNumber>
                )
            }
        return paginations;
    }
    return (
        <>
            {
                paginateNumbers()
            }
        </>
    );
}

PaginateNumber.defaultProps={
    numbers:10
}

PaginateNumber.propTypes={
    numbers:PropTypes.number,
    onChange:PropTypes.func
}