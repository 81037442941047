import React from 'react';
import {Col, Row, Table} from 'antd';
import {connect} from 'react-redux';
import {getMemberReport} from '../../redux/actions/report/getMemberReport';
import {withRouter} from 'react-router-dom';
import currency from 'currency.js';
import {EyeOutlined} from '@ant-design/icons';
import {ParseIntAndFormat} from "./ParseIntAndFormat";

class MemberReport extends React.Component {
    componentDidMount() {
        const {
            match: {
                params: {
                    date,
                    agentName
                }
            }
        } = this.props;
        this.props.onGetReport({date, agentName, type: this.props.type});
    }

    render() {
        const {Column, ColumnGroup} = Table;
        return (
            <Row>
                <Col span={24} style={{
                    width: '100%',
                    overflowX: 'scroll',
                }}>
                    <Table
                        bordered
                        loading={this.props.loading}
                        dataSource={this.props.members}>
                        <Column title="Member Name" dataIndex="userName" key="userName" align="center"/>
                        <ColumnGroup title="Senior Master">
                            <Column title="Bet" dataIndex="masterBetAmount"
                                    render={(text, record) => ParseIntAndFormat(record.masterBetAmount ?? 0)}
                                    key="masterBetAmount" align="right"/>
                            <Column title="Commission" dataIndex="masterCommissionAmount"
                                    render={(text, record) => ParseIntAndFormat(record.masterCommissionAmount ?? 0)}
                                    key="masterCommissionAmount" align="right"/>
                            <Column
                                title="W/L"
                                key="p_win_lose"
                                render={(text, record) => {
                                    let sum = currency(record.masterBetAmount)
                                        .subtract(currency(record.masterWinAmount))
                                        .subtract(currency(record.masterCommissionAmount))
                                        .value;
                                    return ParseIntAndFormat(sum);
                                }
                                }
                                align="right"
                            />
                        </ColumnGroup>
                        <ColumnGroup title="Master">
                            <Column title="Bet" dataIndex="agentBetAmount"
                                    render={(text, record) => ParseIntAndFormat(record.agentBetAmount ?? 0)}
                                    key="agentBetAmount" align="right"/>
                            <Column title="Commission" dataIndex="agentCommissionAmount"
                                    render={(text, record) => ParseIntAndFormat(record.agentCommissionAmount ?? 0)}
                                    key="agentCommissionAmount" align="right"/>
                            <Column
                                title="W/L"
                                key="s_win_lose"
                                render={(text, record) => {
                                    let sum = currency(record.agentBetAmount)
                                        .subtract(currency(record.agentWinAmount))
                                        .subtract(currency(record.agentCommissionAmount))
                                        .value;
                                    return ParseIntAndFormat(sum);
                                }
                                }
                                align="right"
                            />
                            <Column title="Action" key="action" align="center" render={
                                (text, record) => <EyeOutlined onClick={
                                    () => this.props.history.push(`/report/daily/${this.props.match.params.date}/members/${record.userName}/bets`)
                                }/>
                            }/>
                        </ColumnGroup>
                    </Table>
                </Col>
            </Row>
        );
    }
}

const mapStateToProps = state => ({
    loading: state.report.loading,
    error: state.report.error,
    members: state.report.members,
    type: state.report.type
})

const mapDispatchToProps = dispatch => ({
    onGetReport: (obj) => dispatch(getMemberReport(obj)),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MemberReport));