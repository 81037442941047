import { Tabs } from 'antd';
import AgentDetail from './AgentDetail';
import AgentTransactions from './AgentTransactions';

const Dashboard = () => {
    const { TabPane } = Tabs;
    return (
        <Tabs defaultActiveKey="1">
            <TabPane tab="Agent Detail" key="1">
                <AgentDetail />
            </TabPane>
            <TabPane tab="Transaction" key="2">
                <AgentTransactions />
            </TabPane>
        </Tabs>
    );
}

export default Dashboard;