import update from '../utilities/update';
import { loading } from './shared/loading';
import { error } from './shared/error';
import {
   CURRENT_USER_START,
   CURRENT_USER_SUCCESS,
   CURRENT_USER_FAIL,
   UPDATE_USER_BALANCE
} from '../actions/user/actionTypes';

const initialState = {
   loading: false,
   error: null,
   message: null,
   current: null
}

const getCurrent = (state, action) => {
   return update(state, {
      loading: false,
      current: action.payload
   })
}

const updateUserBalance = (state, action) => {
   console.log('response',action.payload);
   let amount = 0;
   if (action.payload?.transactionType === "OUT") {
      amount = state.current?.balance - action.payload?.amount;
   } else {
      amount = state.current?.balance + action.payload?.amount;
   }
   return update(state, {
      current: {
         ...state.current,
         balance: amount
      }
   })
}

const userReducer = (state = initialState, action) => {
   switch (action.type) {
      case CURRENT_USER_START:
         return loading(state, action);
      case CURRENT_USER_SUCCESS:
         return getCurrent(state, action);
      case CURRENT_USER_FAIL:
         return error(state, action);
      case UPDATE_USER_BALANCE:
         return updateUserBalance(state, action);
      default:
         return state;
   }
}

export default userReducer;