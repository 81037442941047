import update from '../utilities/update';
import { loading } from './shared/loading';
import { error } from './shared/error';
import {
      GET_COLLECTION_REPORT_START,
      GET_COLLECTION_REPORT_SUCCESS,
      GET_COLLECTION_REPORT_FAIL,
      AGENT_REPORT_START,
      AGENT_REPORT_SUCCESS,
      AGENT_REPORT_FAIL,
      MAIN_AGENT_REPORT_START,
      MAIN_AGENT_REPORT_SUCCESS,
      MAIN_AGENT_REPORT_FAIL,
      MEMBER_BY_COLLECTION_AND_AGENT,
      MEMBER_BY_COLLECTION_AND_AGENT_SUCCESS,
      MEMBER_BY_COLLECTION_AND_AGENT_FAIL,
      BET_REPORT_START,
      BET_REPORT_SUCCESS,
      BET_REPORT_FAIL,
      SET_BET_TYPE,
      GET_2D_REPORT_START,
      GET_2D_REPORT_SUCCESS,
      GET_2D_REPORT_FAIL,
      GET_3D_REPORT_START,
      GET_3D_REPORT_SUCCESS,
      GET_3D_REPORT_FAIL,
      GET_4D_REPORT_START,
      GET_4D_REPORT_SUCCESS,
      GET_4D_REPORT_FAIL,
} from '../actions/report/actionTypes';

const initialState = {
      loading: false,
      error: null,
      message: null,
      collection: [],
      members: [],
      agents: [],
      mainAgents:[],
      bets: [],
      type: 'SOCCER',
      twoDs: [],
      threeDs: [],
      fourDs: [],
      twoDAverage: 0,
      threeDAverage: 0,
      twoDtotal: 0,
      twoDmax: 0,
      threeDtotal: 0,
      threeDmax: 0,
      twoDmaxNumber: null,
      threeDmaxNumber: null
}

const getCollectionReportStart = (state, action) => {
      return update(state, {
            loading: true,
            error: null,
            message: null,
            collection: [],
            members: [],
            agents: [],
            bets: [],
      })
}

const getCollectionReport = (state, action) => {
      return update(state, {
            loading: false,
            collection: action.payload
      })
}

const getAgentReport = (state, action) => {
      return update(state, {
            loading: false,
            agents: action.payload
      })
}

const getMainAgentReportStart=(state,action)=>{
      return update(state,{
            loading:true,
            error:null,
            message:null,
            mainAgents:[]
      })
}

const getMainAgentReport=(state,action)=>{
      return update(state,{
            loading:false,
            mainAgents:action.payload
      })
}

const getMemberReport = (state, action) => {
      return update(state, {
            loading: false,
            members: action.payload
      })
}

const getBetReport = (state, action) => {
      return update(state, {
            loading: false,
            bets: action.payload
      })
}

const getTwoDReportStart = (state, action) => {
      return update(state, {
            loading: true,
            error: null,
            message: null,
            twoDs: [],
            twoDAverage: 0,
            twoDtotal: 0,
            twoDmax: 0,
            twoDmaxNumber: null
      })
}

const getTwoDReport = (state, action) => {
      let twoDCollection = [];
      let arrLength = action.payload.length;
      let responseArr = action.payload;
      let twoDAverage = 0;
      let total = 0;
      let max = responseArr[0]?.totalBetAmount || 0;
      let twoDmaxNumber = responseArr[0]?.twoDNumber;
      for (let i = 0; i < 100; i++) {
            let number = i < 10 ? `0${i}` : `${i}`;
            twoDCollection.push({
                  twoDNumber: number,
                  totalBetAmount: 0
            })
      }
      for (let i = 0; i < arrLength; i++) {
            twoDCollection[(responseArr[i].twoDNumber - 0)] = responseArr[i];
            total += (responseArr[i]?.totalBetAmount);
            if (max < (responseArr[i]?.totalBetAmount)) {
                  max = (responseArr[i]?.totalBetAmount);
                  twoDmaxNumber = responseArr[i]?.twoDNumber;
            }
      }
      if (max != 0) {
            twoDAverage = total / max;
      }

      return update(state, {
            loading: false,
            twoDs: twoDCollection,
            twoDAverage: twoDAverage.toFixed(),
            twoDtotal: total,
            twoDmax: max,
            twoDmaxNumber
      })
}

const getThreeDReportStart = (state, action) => {
      return update(state, {
            loading: true,
            error: null,
            message: null,
            threeDs: [],
            threeDAverage: 0,
            threeDtotal: 0,
            threeDmax: 0,
            threeDmaxNumber: null
      })
}

const getThreeDReport = (state, action) => {
      let threeDCollection = [];
      let arrLength = action.payload.length;
      let responseArr = action.payload;
      let threeDAverage = 0;
      let total = 0;
      let max = responseArr[0]?.totalBetAmount || 0;
      let threeDmaxNumber = responseArr[0]?.threeDNumber;
      for (let i = 0; i < 1000; i++) {
            let number = i < 10 ? `00${i}` : (i >= 10 && i < 100) ? `0${i}` : `${i}`;
            threeDCollection.push({
                  threeDNumber: number,
                  totalBetAmount: 0
            })
      }
      for (let i = 0; i < arrLength; i++) {
            threeDCollection[(responseArr[i].threeDNumber - 0)] = responseArr[i];
            total += (responseArr[i]?.totalBetAmount);
            if (max < (responseArr[i]?.totalBetAmount)) {
                  max = (responseArr[i]?.totalBetAmount);
                  threeDmaxNumber = responseArr[i]?.threeDNumber;
            }
      }
      if (max != 0) {
            threeDAverage = total / max;
      }
      return update(state, {
            loading: false,
            threeDs: threeDCollection,
            threeDAverage: threeDAverage.toFixed(),
            threeDtotal: total,
            threeDmax: max,
            threeDmaxNumber
      })
}


const getFourDReportStart=(state,action)=>{
      return update(state,{
            loading:true,
            fourDs:[],
            error:null,
            message:null,
      })
}

const getFourDReport = (state, action) => {
      let fourDCollection = [];
      let arrLength = action.payload.length;
      let responseArr = action.payload;
      for (let i = 0; i < 10000; i++) {
            let number = i < 10
                  ? `000${i}`
                  : (i >= 10 && i < 100)
                        ? `00${i}`
                        : (i >= 100 && i < 1000)
                              ? `0${i}`
                              : `${i}`;
            fourDCollection.push({
                  fourDNumber: number,
                  totalBetAmount: 0
            })
      }
      for (let i = 0; i < arrLength; i++) {
            fourDCollection[(responseArr[i].fourDNumber - 0)] = responseArr[i];
      }
      return update(state, {
            loading: false,
            fourDs: fourDCollection,
      })
}

const reportReducer = (state = initialState, action) => {
      switch (action.type) {
            case GET_COLLECTION_REPORT_START:
                  return getCollectionReportStart(state, action);
            case GET_COLLECTION_REPORT_SUCCESS:
                  return getCollectionReport(state, action);
            case GET_COLLECTION_REPORT_FAIL:
                  return error(state, action);
            case MAIN_AGENT_REPORT_START:
                  return getMainAgentReportStart(state,action);
            case MAIN_AGENT_REPORT_SUCCESS:
                  return getMainAgentReport(state,action);
            case MAIN_AGENT_REPORT_FAIL:
                  return error(state,action);
            case AGENT_REPORT_START:
                  return loading(state, action);
            case AGENT_REPORT_SUCCESS:
                  return getAgentReport(state, action);
            case AGENT_REPORT_FAIL:
                  return error(state, action);
            case MEMBER_BY_COLLECTION_AND_AGENT:
                  return loading(state, action);
            case MEMBER_BY_COLLECTION_AND_AGENT_SUCCESS:
                  return getMemberReport(state, action);
            case MEMBER_BY_COLLECTION_AND_AGENT_FAIL:
                  return error(state, action);
            case BET_REPORT_START:
                  return loading(state, action);
            case BET_REPORT_SUCCESS:
                  return getBetReport(state, action);
            case BET_REPORT_FAIL:
                  return error(state, action);
            case GET_2D_REPORT_START:
                  return getTwoDReportStart(state, action);
            case GET_2D_REPORT_SUCCESS:
                  return getTwoDReport(state, action);
            case GET_2D_REPORT_FAIL:
                  return error(state, action);
            case GET_3D_REPORT_START:
                  return getThreeDReportStart(state, action);
            case GET_3D_REPORT_SUCCESS:
                  return getThreeDReport(state, action);
            case GET_3D_REPORT_FAIL:
                  return error(state, action);
            case SET_BET_TYPE:
                  return update(state, { type: action.payload });
            case GET_4D_REPORT_START:
                  return getFourDReportStart(state, action);
            case GET_4D_REPORT_SUCCESS:
                  return getFourDReport(state, action);
            case GET_4D_REPORT_FAIL:
                  return error(state, action);
            default:
                  return state;
      }
}

export default reportReducer;