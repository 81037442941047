import {useEffect} from 'react';
import {Col, Row, Table} from 'antd';
import {useParams} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {getBetDetail} from '../../redux/actions/betPattern/getBetDetail';
import BetInformations from './BetInformations';
import moment from 'moment';
import {ParseIntAndFormat} from "../Report/ParseIntAndFormat";

const columns = [
    {
        title: 'Home',
        key: 'home',
        render: (text, record) => {
            return <>
                {record?.match?.home.name}
                {record?.betType === "BODY" ? (record?.homeUpper ?
                    `(${record?.odds}${record?.price >= 0 ?
                        '+' + record?.price : record?.price})`
                    : null) : null}
            </>
        }
    },
    {
        title: 'Goal Score',
        key: 'goalScore',
        render: (text, record) => {
            return `${record?.match?.homeScore}-${record?.match?.awayScore}`
        },
        align: 'center'
    },
    {
        title: 'Away',
        key: 'away',
        render: (text, record) => {
            return <>
                {record?.match?.away.name}
                {record?.betType === "BODY" ? (record?.homeUpper ?
                    null
                    : `(${record?.odds}${record?.price >= 0 ?
                        '+' + record?.price : record?.price})`) : null}
            </>
        },
        align: 'right'
    },
    {
        title: 'Goal Price',
        key: 'goalPrice',
        render: (text, record) => record?.betType === "BODY" ? '-' : `(${record?.odds}
            ${record?.price >= 0 ?
            '+' + record?.price : record?.price})`,
        align: 'center'
    },
    {
        title: 'Bet Choice',
        align: 'right',
        render: (text, record) => {
            return <>
                {
                    record?.betType === "BODY" ? getBetChoice(record) : record?.betChoice
                }
            </>
        }
    }
]

const getBetChoice = (record) => {

    if (record?.homeUpper && record?.betChoice === "UPPER") {
        return record?.match?.home?.name
    } else if (!record?.homeUpper && record?.betChoice === "LOWER") {
        return record?.match?.home?.name
    } else
        return record?.match?.away?.name
}

const BetDetail = () => {
    const {id} = useParams();
    const loading = useSelector(state => state.betPattern.loading);
    const detail = useSelector(state => state.betPattern.betDetail);
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getBetDetail(id));
    }, [])
    return <>
        <Row>
            <Col span={24}>
                <BetInformations
                    id={detail?.id}
                    date={moment(detail?.betTime).format('YYYY-MM-DD hh:mm:ss a')}
                    masterBetAmount={ParseIntAndFormat(detail?.masterBetAmount ?? 0)}
                    agentBetAmount={ParseIntAndFormat(detail?.agentBetAmount)}
                    userBetAmount={ParseIntAndFormat(detail?.userBetAmount)}
                    masterCommissionAmount={ParseIntAndFormat(detail?.masterCommissionAmount)}
                    agentCommissionAmount={ParseIntAndFormat(detail?.agentCommissionAmount)}
                    userCommissionAmount={ParseIntAndFormat(detail?.userCommissionAmount)}
                    masterWinAmount={ParseIntAndFormat(detail?.masterWinAmount)}
                    agentWinAmount={ParseIntAndFormat(detail?.agentWinAmount)}
                    userWinAmount={ParseIntAndFormat(detail?.agentWinAmount)}
                    status={detail?.betStatus}
                    date={detail?.betTime}
                />
                <Row>
                    <Col span={24} style={{
                        width: '100%',
                        overflowX: 'scroll',
                    }}>
                        <Table
                            columns={columns}
                            dataSource={
                                detail?.betMatches
                            }
                            loading={loading}
                        />
                    </Col>
                </Row>
            </Col>
        </Row>
    </>
}

export default BetDetail