import axios from 'axios';
import {api} from '../../utilities/api';
import * as actionTypes from './actionTypes';
import {showAgreement} from '../agreement/toggleAgreementModal';

const TOKEN_VALID_TIME_IN_DAY=1;

const TOKEN_VALID_TIME_IN_SECONDS=TOKEN_VALID_TIME_IN_DAY * 24 * 60 * 60;

export const authStart = () => {
    return {
        type: actionTypes.AUTH_START
    };
};

export const authSuccess = (token) => {
    return {
        type: actionTypes.AUTH_SUCCESS,
        token: token,
    };
};

export const authFail = (error) => {
    return {
        type: actionTypes.AUTH_FAIL,
        payload: error
    };
};

export const logout = () => {
    localStorage.removeItem('555Agreement');
    localStorage.removeItem('555mixMasterToken');
    localStorage.removeItem('expirationDate');
    return {
        type: actionTypes.AUTH_LOGOUT
    };
};

export const checkAuthTimeout = (expirationTime) => {
    return dispatch => {
        setTimeout(() => {
            dispatch(logout());
        }, expirationTime * 1000);
    };
};

export const auth = (username, password) => {
    return dispatch => {
        //authenticate the user
        dispatch(authStart());
        let url = `${api}/auth/master/login`;
        const options = {
            url: url,
            method: 'POST',
            headers: {},
            data: {
                username: username,
                password: password
            }
        };
        axios(options)
            .then(response => {
                const expirationDate = new Date(
                    new Date().getTime() + TOKEN_VALID_TIME_IN_SECONDS * 1000
                );
                localStorage.setItem("555mixMasterToken", response.data.accessToken);
                localStorage.setItem("expirationDate", expirationDate);
                dispatch(authSuccess(response.data.accessToken));
                dispatch(checkAuthTimeout(TOKEN_VALID_TIME_IN_SECONDS));
                dispatch(showAgreement());
            })
            .catch(err => {
                dispatch(authFail(err));
            });
    };
};

export const authCheckState = () => {
    return dispatch => {
        const token = localStorage.getItem("555mixMasterToken");
        const agree=localStorage.getItem("555Agreement");
        if(!agree){
            dispatch(showAgreement());
        }
        if (!token) {
            dispatch(logout());
        } else {
            const expirationDate = new Date(localStorage.getItem("expirationDate"));
            if (expirationDate <= new Date()) {
                dispatch(logout());
            } else {
                dispatch(authSuccess(token));
                dispatch(
                    checkAuthTimeout(
                        (expirationDate.getTime() - new Date().getTime()) / 1000
                    )
                );
            }
        }
    };
};