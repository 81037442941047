import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Table, Form, Button, DatePicker, message, Row, Col } from 'antd';
import moment from 'moment';
import { useParams, useHistory } from 'react-router-dom';
import {
   SearchOutlined,
   EyeOutlined
} from '@ant-design/icons';
import { getAgentTransactions } from '../../redux/actions/transaction/getAgentTransactions'

export default function AgentTransactions() {
   const { id } = useParams();
   const history = useHistory();
   const { RangePicker } = DatePicker;
   const dispatch = useDispatch();
   const { error, loading, agentTransaction } = useSelector(state => state.transaction);
   const [dates, setDates] = useState({
      startDate: null,
      endDate: null
   });
   const query = window.matchMedia(`(max-width: 480px)`);
   const [isMobile, setIsMobile] = useState(query.matches);
   query.onchange = (e) => {
      setIsMobile(e.matches);
   }

   const columns = [
      {
         title: 'Date',
         dataIndex: 'transactionDate',
         key: 'transactionDate',
         render: (text, record) => moment(record.transactionDate).format('YYYY-MM-DD')
      },
      {
         title: 'Transfer In',
         dataIndex: 'transferInAmount',
         key: 'transferInAmount',
         align: 'right',
         render: (text, record) => `${record.transferInAmount}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      },
      {
         title: 'Transfer Out',
         dataIndex: 'transferOutAmount',
         key: 'transferOutAmount',
         align: 'right',
         render: (text, record) => `${record.transferOutAmount}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      },
      {
         title: 'Commission Amount',
         dataIndex: 'commissionAmount',
         key: 'commissionAmount',
         align: 'right',
         render: (text, record) => `${record.commissionAmount}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      },
      {
         title: 'Balance',
         dataIndex: 'closingBalance',
         key: 'closingBalance',
         align: 'right',
         render: (text, record) => `${record.closingBalance}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      },
      {
         title: 'Action',
         dataIndex: 'action',
         key: 'action',
         align: 'center',
         render: (text, record) => <EyeOutlined onClick={
            () => history.push(`/agents/${id}/${record.transactionDate}`)} />
      },
   ]
   const onFinish = async (values) => {
      await dispatch(getAgentTransactions(id, dates));
      if (error) {
         message.error(error);
      }
   }
   const onFinishFailed = (errors) => {
      console.log(errors);
   }
   const dateHandler = (value, dateString) => {
      setDates({
         startDate: dateString[0],
         endDate: dateString[1]
      })
   }
   return (
      <>
         <Row gutter={[16, 10]}>
            <Col span={{ lg: 12, md: 12, sm: 24 }}>
               <Form
                  style={{
                     marginTop: 30,
                     marginBottom: 30,
                  }}
                  name="dates"
                  layout={isMobile ? "horizontal" : "inline"}
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
               >
                  <Form.Item
                     name="dates"
                     rules={[{ required: true, message: 'require' }]}
                  >
                     <RangePicker onChange={dateHandler} format="YYYY-MM-DD" />
                  </Form.Item>
                  <Form.Item >
                     <Button type="primary"
                        htmlType="submit" icon={<SearchOutlined />} loading={loading}>
                        Search
                     </Button>
                  </Form.Item>
               </Form>
            </Col>
            <Col span={24} style={{
               width: '100%',
               overflowX: 'scroll',
            }}>
               <Table columns={columns} dataSource={agentTransaction} style={{ marginTop: 20 }} />
            </Col>
         </Row>
      </>
   );
}