import { useState } from "react";
const BetInformations = ({
   id,
   masterBetAmount,
   agentBetAmount,
   userBetAmount,
   masterCommissionAmount = 0,
   agentCommissionAmount = 0,
   userCommissionAmount = 0,
   masterWinAmount,
   agentWinAmount,
   userWinAmount,
   status,
   date
}) => {
   const query = window.matchMedia(`(max-width: 480px)`);
   const [isMobile, setIsMobile] = useState(query.matches);
   query.onchange = (e) => {
      setIsMobile(e.matches);
   }
   const styles = {
      container: {
         width: '100%',
         padding: '30px',
         display: 'flex',
         color: '#666',
         lineHeight: '2rem',
         flexDirection: isMobile ? "column" : "row"
      },
      child: {
         flex: 1
      }
   }
   return <>
      <div style={styles.container}>
         <div style={styles.child}>
            <div>BET ID : {id}</div>
            {date && <div> Time : {(new Date(date)).toLocaleString()}</div>}
         </div>
         <div style={styles.child}>
            <div>Senior Master Bet : {masterBetAmount}</div>
            <div>Master Bet : {agentBetAmount}</div>
            <div>User Bet : {userBetAmount}</div>
         </div>
         <div style={styles.child}>
            <div>Senior Master % : {masterCommissionAmount}</div>
            <div>Master % : {agentCommissionAmount}</div>
            <div>User % : {userCommissionAmount}</div>
         </div>
         <div style={styles.child}>
            <div>Senior Master Win : {masterWinAmount}</div>
            <div>Master Win : {agentWinAmount}</div>
            <div>User Win : {userWinAmount}</div>
         </div>
         <div style={styles.child}>Status : {status}</div>
      </div>
   </>
}

export default BetInformations;