import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Button,Spin, Modal } from 'antd';
import { hideAgreement } from '../../redux/actions/agreement/toggleAgreementModal';

class Agreement extends React.Component {
    onAgree = () => {
        localStorage.setItem("555Agreement","555Agreement");
        this.props.onClose();
    }
    onDisagree = async () => {
        await this.props.onClose();
        await this.props.history.push("/logout");
    }
    render() {
        const body=this.props.loading?(<div style={{
            marginTop:'20px',
            marginBottom:'20px',
            width:'100%',
            textAlign:'center'
        }}><Spin size="middle"/></div>):(<div style={{
            textAlign: 'justify',
            margin: '15px',
            padding: '10px',
            width:'100%',
            maxHeight:'80vh',
            overflowY:'scroll'
        }}>
            {this.props.agreement?.statement}
        </div>);
        return (
            <>
                <Modal
                    title={<div style={{
                        textAlign: 'center',
                        color: '#0E0E0E',
                        fontSize: '20px',
                        fontWeight: 500,
                    }}>Terms and Conditions</div>}
                    key="agreement"
                    centered
                    visible={this.props.visible}
                    closable={false}
                    footer={null}
                >
                    {body}
                    <div style={{
                        display: 'flex',
                        justifyContent: 'center'
                    }}>
                        <Button
                            disabled={this.props.loading}
                            onClick={this.onAgree}
                            style={{
                                marginRight: '30px',
                                background: 'green',
                                color: 'white',
                                textTransform:'uppercase'
                            }}>
                            Agree
                        </Button>
                        <Button
                            disabled={this.props.loading}
                            onClick={this.onDisagree}
                            style={{
                                background: 'red',
                                color: 'white',
                                textTransform:'uppercase'
                            }}>
                            Disagree
                        </Button>
                    </div>
                </Modal>
            </>
        )
    }
}

const mapStateToProps = state => ({
    agreement: state.agreement.one,
    visible: state.agreement.visible,
    loading:state.agreement.loading
})

const mapDispatchToProps = dispatch => ({
    onClose: () => dispatch(hideAgreement()),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Agreement));