import * as actionTypes from './actionTypes';
import axios from '../../utilities/interceptor';
import { getMessage } from '../../utilities/getMessage';

export const getBetReport=({
    date,
    memberName,
    type
})=>async dispatch=>{
    dispatch({
        type:actionTypes.BET_REPORT_START,
    })
    await axios.get(`/bets/daily?userName=${memberName}&date=${date}&type=${type}`)
            .then(response=> dispatch({
                type:actionTypes.BET_REPORT_SUCCESS,
                payload:response.data
            }))
            .catch(error=>dispatch({
                type:actionTypes.BET_REPORT_FAIL,
                payload:getMessage(error)
            }))
}