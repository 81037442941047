import React from 'react';
import {Button, Col, DatePicker, Form, message, Row, Select, Table} from 'antd';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {getCollectionReport} from '../../redux/actions/report/getCollectionReport';
import {setBetType} from '../../redux/actions/report/setBetType';
import moment from 'moment';
import currency from 'currency.js';
import './index.css';
import {EyeOutlined} from '@ant-design/icons';
import {ParseIntAndFormat} from "./ParseIntAndFormat";

class CollectionReport extends React.Component {
    state = {
        startDate: null,
        endDate: null,
        isMobile: false,
    }

    componentDidMount() {
        const query = window.matchMedia(`(max-width: 480px)`);
        query.onchange = (e) => {
            this.setState(prevState => {
                return {
                    ...prevState,
                    isMobile: e.matches
                }
            })
        }
        this.setState({
            isMobile: query.matches
        })
    }

    dateHandler = (value, dateString) => {
        this.setState(prevState => {
            return {
                ...prevState,
                startDate: dateString[0],
                endDate: dateString[1]
            }
        });
    }
    onFinish = async (values) => {
        const {startDate, endDate} = this.state;
        await this.props.onGetReport({startDate, endDate, type: this.props.type});
        if (this.props.error) {
            message.error(this.props.error);
        }
    }
    onFinishFailed = (errors) => {
        console.warn(errors);
    }

    render() {
        const {RangePicker} = DatePicker;
        const {Column, ColumnGroup} = Table;
        const {isMobile} = this.state;
        const {Option} = Select;
        return (
            <Row gutter={[16, 10]}>
                <Col span={{lg: 12, md: 12, sm: 24}}>
                    <Form
                        name="dates"
                        layout={isMobile ? "horizontal" : "inline"}
                        onFinish={this.onFinish}
                        onFinishFailed={this.onFinishFailed}
                    >
                        {this.props.master?.twoThreeAllowed ? (
                            <Form.Item>
                                <Select
                                    defaultValue={this.props.type}
                                    onChange={(value) => this.props.onSetBetType(value)}>
                                    <Option value="SOCCER">Soccer</Option>
                                    {/* <Option value="TWOD">Two D</Option> */}
                                    <Option value="THREED">Three D</Option>
                                    <Option value="FOURD">Four D</Option>
                                </Select>
                            </Form.Item>
                        ) : null}
                        <Form.Item
                            name="dates"
                            validateStatus="warning"
                        >
                            <RangePicker onChange={this.dateHandler} format="YYYY-MM-DD"/>
                        </Form.Item>
                        <Form.Item>
                            <Button type="primary" htmlType="submit">
                                Load
                            </Button>
                        </Form.Item>
                    </Form>
                </Col>
                <Col span={24} style={{
                    width: '100%',
                    overflowX: 'scroll',
                }}>
                    <Table
                        bordered
                        loading={this.props.loading}
                        dataSource={this.props.collectionReport}>
                        <Column
                            title="Date"
                            dataIndex="date"
                            key="date"
                            align="center"
                            render={(text, record) => moment(record.date).locale('my').format('DD-MM-YYYY')}
                        />
                        <ColumnGroup title="Senior Master">
                            <Column title="Bet" dataIndex="masterBetAmount"
                                    render={(text, record) => ParseIntAndFormat(record.masterBetAmount ?? 0)}
                                    key="masterBetAmount" align="right"/>
                            <Column title="Commission" dataIndex="masterCommissionAmount"
                                    render={(text, record) => ParseIntAndFormat(record.masterCommissionAmount ?? 0)}
                                    key="masterCommissionAmount" align="right"/>
                            <Column
                                title="W/L"
                                key="p_win_lose"
                                render={(text, record) => {
                                    let sum = currency(record.masterBetAmount)
                                        .subtract(currency(record.masterWinAmount))
                                        .subtract(currency(record.masterCommissionAmount))
                                        .value;
                                    return ParseIntAndFormat(sum);
                                }
                                }
                                align="right"
                            />
                        </ColumnGroup>
                        <ColumnGroup title="Master">
                            <Column title="Bet" dataIndex="agentBetAmount"
                                    render={(text, record) => ParseIntAndFormat(record.agentBetAmount ?? 0)}
                                    key="agentBetAmount" align="right"/>
                            <Column title="Commission" dataIndex="agentCommissionAmount"
                                    render={(text, record) => ParseIntAndFormat(record.agentCommissionAmount ?? 0)}
                                    key="agentCommissionAmount" align="right"/>
                            <Column
                                title="W/L"
                                key="s_win_lose"
                                render={(text, record) => {
                                    let sum = currency(record.agentBetAmount)
                                        .subtract(currency(record.agentWinAmount))
                                        .subtract(currency(record.agentCommissionAmount))
                                        .value;
                                    return ParseIntAndFormat(sum);
                                }
                                }
                                align="right"
                            />
                        </ColumnGroup>
                        <Column title="Action" key="action" align="center" render={
                            (text, record) => <EyeOutlined onClick={
                                () => this.props.history.push(`/report/daily/${record.date}/masters/${this.props.master?.username}/agents`)
                            }/>
                        }/>
                    </Table>
                </Col>
            </Row>
        );
    }
}

const mapStateToProps = state => ({
    loading: state.report.loading,
    error: state.report.error,
    collectionReport: state.report.collection,
    type: state.report.type,
    master: state.user?.current
})

const mapDispatchToProps = dispatch => ({
    onGetReport: (obj) => dispatch(getCollectionReport(obj)),
    onSetBetType: (type) => dispatch(setBetType(type))
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CollectionReport));