import * as actionTypes from './actionTypes';
import axios from '../../utilities/interceptor';
import { getMessage } from '../../utilities/getMessage';

export const createAgent=(request)=>async dispatch=>{
    dispatch({
        type:actionTypes.CREATE_AGENT_START
    })
    const options={
        url:`/auth/agent/signup`,
        method:'POST',
        data:request
    }
    await axios(options)
            .then(response=>dispatch({
                type:actionTypes.CREATE_AGENT_SUCCESS,
                payload:response.data
            }))
            .catch(error=>dispatch({
                type:actionTypes.CREATE_AGENT_FAIL,
                payload:getMessage(error)
            }))
}