import * as actionTypes from './actionTypes';
import axios from '../../utilities/interceptor';
import { getMessage } from '../../utilities/getMessage';

export const getMasterTransaction=({startDate,endDate})=>async dispatch=>{
    dispatch({
        type:actionTypes.GET_MASTER_TRANSACTION_START
    })
    await axios.get(`/transactions/filter/${startDate}/${endDate}`)
                .then(response=>dispatch({
                    type:actionTypes.GET_MASTER_TRANSACTION_SUCCESS,
                    payload:response.data
                }))
                .catch(error=>dispatch({
                    type:actionTypes.GET_MASTER_TRANSACTION_FAIL,
                    payload:getMessage(error)
                }))
}