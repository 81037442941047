export const GET_COLLECTION_REPORT_START='GET_COLLECTION_REPORT_START';
export const GET_COLLECTION_REPORT_SUCCESS='GET_COLLECTION_REPORT_SUCCESS';
export const GET_COLLECTION_REPORT_FAIL='GET_COLLECTION_REPORT_FAIL';

export const GET_4D_REPORT_START='GET_4D_REPORT_START';
export const GET_4D_REPORT_SUCCESS='GET_4D_REPORT_SUCCESS';
export const GET_4D_REPORT_FAIL='GET_4D_REPORT_FAIL';

export const AGENT_REPORT_START='AGENT_REPORT_START';
export const AGENT_REPORT_SUCCESS='AGENT_REPORT_SUCCESS';
export const AGENT_REPORT_FAIL='AGENT_REPORT_FAIL';

export const MAIN_AGENT_REPORT_START='MAIN_AGENT_REPORT_START';
export const MAIN_AGENT_REPORT_SUCCESS='MAIN_AGENT_REPORT_SUCCESS';
export const MAIN_AGENT_REPORT_FAIL='MAIN_AGENT_REPORT_FAIL';

export const MEMBER_BY_COLLECTION_AND_AGENT='MEMBER_BY_COLLECTION_AND_AGENT';
export const MEMBER_BY_COLLECTION_AND_AGENT_SUCCESS='MEMBER_BY_COLLECTION_AND_AGENT_SUCCESS';
export const MEMBER_BY_COLLECTION_AND_AGENT_FAIL='MEMBER_BY_COLLECTION_AND_AGENT_FAIL';

export const BET_REPORT_START='BET_REPORT_START';
export const BET_REPORT_SUCCESS='BET_REPORT_SUCCESS';
export const BET_REPORT_FAIL='BET_REPORT_FAIL';

export const GET_2D_REPORT_START='GET_2D_REPORT_START';
export const GET_2D_REPORT_SUCCESS='GET_2D_REPORT_SUCCESS';
export const GET_2D_REPORT_FAIL='GET_2D_REPORT_FAIL';

export const GET_3D_REPORT_START='GET_3D_REPORT_START';
export const GET_3D_REPORT_SUCCESS='GET_3D_REPORT_SUCCESS';
export const GET_3D_REPORT_FAIL='GET_3D_REPORT_FAIL';

export const SET_BET_TYPE='SET_BET_TYPE';