import update from '../utilities/update';
import { loading } from './shared/loading';
import { error } from './shared/error';
import {
    GET_AGREEMENT_START,
    GET_AGREEMENT_SUCCESS,
    GET_AGREEMENT_FAIL,
    SHOW_AGREEMENT,
    HIDE_AGREEMENT
} from '../actions/agreement/actionTypes';

const initialState={
    loading:false,
    error:null,
    message:null,
    one:null,
    visible:false,
}

const getAgreement=(state,action)=>{
    return update(state,{
        loading:false,
        one:action.payload
    })
}

const showAgreement=(state,action)=>{
    return update(state,{
        visible:true
    })
}

const hideAgreement=(state,action)=>{
    return update(state,{
        visible:false,
    })
}

const agreementReducer=(state=initialState,action)=>{
    switch(action.type)
    {
        case GET_AGREEMENT_START:
            return loading(state,action);
        case GET_AGREEMENT_SUCCESS:
            return getAgreement(state,action);
        case GET_AGREEMENT_FAIL:
            return error(state,action);
        case SHOW_AGREEMENT:
            return showAgreement(state,action);
        case HIDE_AGREEMENT:
            return hideAgreement(state,action);
        default:
            return state;
    }
}

export default agreementReducer;