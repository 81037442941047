import * as actionTypes from "./actionTypes";
import axios from '../../utilities/interceptor';
import {api} from '../../utilities/api';
import { getMessage } from '../../utilities/getMessage';

export const get4DReport=()=>async dispatch=>{
    dispatch({
        type:actionTypes.GET_4D_REPORT_START
    });
    const options={
        url:`${api}/fourDReports/master`,
        method:'GET',
    }
    await axios(options)
        .then(response=>dispatch({
            type:actionTypes.GET_4D_REPORT_SUCCESS,
            payload:response.data
        }))
        .catch(error=>dispatch({
            type:actionTypes.GET_4D_REPORT_FAIL,
            payload:getMessage(error)
        }))
}