import jsPDF from "jspdf";
import "jspdf-autotable";
import moment from 'moment';

const generatePDF = (type, collection) => {
      // initialize jsPDF
      const doc = new jsPDF();

      // define the columns we want and their titles
      const tableColumn = ["Number", "Amount", "Number", "Amount"];
      // define an empty array of rows
      const tableRows = [];

      let itemData = [];

      const lastIndex=collection.length-1;

      collection.forEach((item,index) => {
            if (itemData.length === 4) {
                  // push each tickcet's info into a row
                  tableRows.push(itemData);
                  itemData = [];
            }
            itemData.push((type === '4D') ? item.fourDNumber : item.threeDNumber,
                  `${item.totalBetAmount}`.replace(/\B(?=(\d{3})+(?!\d))/g, ','));
            if(index===lastIndex){
                  tableRows.push(itemData)
            }
      });

      //title
      const time = moment().locale('my').format('DD/MM/YYYY hh:mm a')
      doc.text(`${type} Report ${time}`, 15, 10);
      // startY is basically margin-top
      doc.autoTable(tableColumn, tableRows, {
            theme: 'grid',
            headerStyles: { halign: 'right' },
            margin: { top: 20 },
            columnStyles: { 
                  0: { halign: 'right' }, 
                  1: { halign: 'right' } ,
                  2: { halign: 'right' } ,
                  3: { halign: 'right' } ,
            }
      });
      const dateStr = moment().locale('my').format('DD-MM-YYYY_hh_mm_a')
      // we define the name of our PDF file.
      doc.save(`${type}Report_${dateStr}.pdf`);
};

export default generatePDF;