import * as actionTypes from './actionTypes';
import axios from '../../utilities/interceptor';
import { getMessage } from '../../utilities/getMessage';
import { updateUserBalance } from '../user/updateUserBalance';
import { updateAgentBalance } from '../agent/updateAgentBalance';

export const createTransaction = ({
   agentId,
   command,
   amount,
   credit
}) => async dispatch => {
   dispatch({
      type: actionTypes.CREATE_TRANSACTION_START
   })
   const options = {
      url: `/transactions/agents/${agentId}`,
      method: 'POST',
      data: {
         command,
         amount,
         credit
      }
   }
   await axios(options)
      .then(response => {
         console.log('response',response);
         dispatch({
            type: actionTypes.CREATE_TRANSACTION_SUCCESS,
            payload: response.data
         });
         dispatch(updateAgentBalance(response.data));
         dispatch(updateUserBalance(response.data));
      })
      .catch(error => dispatch({
         type: actionTypes.CREATE_TRANSACTION_FAIL,
         payload: getMessage(error)
      }))
}