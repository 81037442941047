import React from 'react';
import {connect} from 'react-redux';
import {Col, Form, InputNumber, message, Modal, Row} from 'antd';
import * as actionTypes from '../../redux/actions/agent/actionTypes';
import {updateBetLimit} from '../../redux/actions/agent/updateAgent';


class UpdateBetLimitationModal extends React.Component {
    constructor(props) {
        super(props);
        this.form = React.createRef();
    }

    onFinish = async (values) => {
        await this.props.onUpdate({
            id: this.props.agent?.id,
            limitId: this.props.agent?.betLimitation?.id,
            maxForSingleBet: values.maxForSingleBet,
            maxForMixBet: values.maxForMixBet,
            maxForThreeD: values.maxForThreeD,
            maxForFourD:values.maxForFourD,
        });
        if (this.props.error) {
            message.error(this.props.error);
        }
        if (this.props.message) {
            message.success(this.props.message);
            this.form.current.resetFields();
            this.props.onClose();
        }
    }
    onFinishFailed = (errors) => {
        console.log(errors)
    }
    handleCancel = () => {
        this.props.onClose();
    }

    render() {
        return (
            <Modal
                width={800}
                title="Update Bet Limitation"
                key={this.props.agent?.id}
                centered
                visible={this.props.visible}
                okButtonProps={{form: 'update-bl-form', key: 'submit', htmlType: 'submit'}}
                confirmLoading={this.props.loading}
                onCancel={this.handleCancel}
                okText="Save"
                loading={this.props.loading}
            >
                <Form
                    layout={"vertical"}
                    ref={this.form}
                    style={{margin: 25}}
                    id="update-bl-form"
                    name="basic"
                    initialValues={{
                        maxForSingleBet: this.props.agent?.betLimitation?.maxForSingleBet,
                        maxForMixBet: this.props.agent?.betLimitation?.maxForMixBet,
                        maxForThreeD: this.props.agent?.betLimitation?.maxForThreeD,
                        maxForFourD: this.props.agent?.betLimitation?.maxForFourD ??0,
                    }}
                    onFinish={this.onFinish}
                    onFinishFailed={this.onFinishFailed}
                >
                    <Row>

                        <Col lg={12} md={12} sm={12}>
                            <Form.Item
                                name="maxForMixBet"
                                label="Max For Mix Bet"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Require!',
                                    }
                                ]}
                                hasFeedback
                            >
                                <InputNumber style={{width: 150}}/>
                            </Form.Item>
                        </Col>
                        <Col lg={12} md={12} sm={12}>
                            <Form.Item
                                name="maxForSingleBet"
                                label="Max For Single Bet"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Require!',
                                    }
                                ]}
                                hasFeedback
                            >
                                <InputNumber style={{width: 150}}/>
                            </Form.Item>
                        </Col>
                        <Col lg={12} md={12} sm={12}>
                            <Form.Item
                                name="maxForThreeD"
                                label="Max For Three D"
                                rules={[
                                    {
                                        type: 'number',
                                        required: true,
                                        message: `Require minimum 5000 to maximum ${this.props.master?.betLimitation?.maxForThreeD ?? 0}!`,
                                        min: 5000,
                                        max: this.props.master?.betLimitation?.maxForThreeD ?? 5000
                                    }
                                ]}
                                hasFeedback
                            >
                                <InputNumber style={{width: 150}}/>
                            </Form.Item>
                        </Col>
                        <Col lg={12} md={12} sm={12}>
                            <Form.Item
                                name="maxForFourD"
                                label="Max For Four D"
                                rules={[
                                    {
                                        type: 'number',
                                        required: true,
                                        message: `Require minimum 5000 to maximum ${this.props.master?.betLimitation?.maxForFourD ?? 0}!`,
                                        min: 5000,
                                        max: this.props.master?.betLimitation?.maxForFourD ?? 5000
                                    }
                                ]}
                                hasFeedback
                            >
                                <InputNumber style={{width: 150}}/>
                            </Form.Item>
                        </Col>
                    </Row>

                </Form>
            </Modal>
        );
    }
}

const mapStateToProps = state => ({
    visible: state.agent.updateBetLimitVisible,
    loading: state.agent.loading,
    error: state.agent.error,
    message: state.agent.message,
    agent: state.agent.one,
    master: state.user.current
})

const mapDispatchToProps = dispatch => ({
    onUpdate: (obj) => dispatch(updateBetLimit(obj)),
    onClose: () => dispatch({
        type: actionTypes.TOGGLE_BET_LIMIT_UPDATE
    }),
})

export default connect(mapStateToProps, mapDispatchToProps)(UpdateBetLimitationModal);