import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Button, DatePicker, message, Row, Col } from 'antd';
import { getAgents } from '../../redux/actions/agent/getAgents';
import { getMasterTransaction } from '../../redux/actions/transaction/getMasterTransaction';
import { getCurrentUser } from '../../redux/actions/user/getCurrentUser';
import {
      TeamOutlined,
      DollarOutlined,
      SearchOutlined,
      FundViewOutlined
} from '@ant-design/icons';
import TransactionTable from './TransactionTable';

export default function () {
      const { RangePicker } = DatePicker;
      const dispatch = useDispatch();
      const agents = useSelector(state => state.agent.all);
      const {
            balance,
            downLineBalance
      } = useSelector(state => {
            return {
                  balance:state.user.current?.balance,
                  downLineBalance:state.user.current?.downLineBalance??0
            }
      });
      const { error, loading, masterTransaction } = useSelector(state => state.transaction);
      const [dates, setDates] = useState({
            startDate: null,
            endDate: null
      });
      const query = window.matchMedia(`(max-width: 480px)`);
      const [isMobile, setIsMobile] = useState(query.matches);
      query.onchange = (e) => {
            setIsMobile(e.matches);
      }
      useEffect(() => {
            dispatch(getAgents());
            dispatch(getCurrentUser());
      }, []);
      const onFinish = async (values) => {
            await dispatch(getMasterTransaction(dates));
            if (error) {
                  message.error(error);
            }
      }
      const onFinishFailed = (errors) => {
            console.log(errors);
      }
      const dateHandler = (value, dateString) => {
            setDates({
                  startDate: dateString[0],
                  endDate: dateString[1]
            })
      }
      const loadingUser = useSelector(state => state.user.loading);
      const addCommaToNumber = (num) => loadingUser ? '...loading' : `${num}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      return (
            <>
                  <div style={styles.boxWrapper}>
                        <div style={styles.box}>
                              <div style={{
                                    ...styles.icon,
                                    ...styles.masterIcon
                              }}>
                                    <TeamOutlined />
                              </div>
                              <div style={{
                                    ...styles.master,
                                    ...styles.numWrapper
                              }}>
                                    <span style={styles.number}>{agents.length}</span>
                                    <span>Agents</span>
                              </div>
                        </div>
                        <div style={styles.box}>
                              <div style={{
                                    ...styles.icon,
                                    ...styles.balanceIcon
                              }}>
                                    <DollarOutlined />
                              </div>
                              <div
                                    key="balance-wrapper"
                                    style={{
                                          ...styles.numWrapper,
                                          ...styles.balance
                                    }}>
                                    <span key="balance" style={styles.number}>{addCommaToNumber(balance)}</span>
                                    <span>Balance</span>
                              </div>
                        </div>
                        <div style={styles.box}>
                              <div style={{
                                    ...styles.icon,
                                    ...styles.downlineIcon
                              }}>
                                    <FundViewOutlined />
                              </div>
                              <div style={{
                                    ...styles.downline,
                                    ...styles.numWrapper
                              }}>
                                    <span style={styles.number}>{downLineBalance}</span>
                                    <span>Downline Balance</span>
                              </div>
                        </div>
                  </div>
                  <Form
                        style={{
                              marginTop: 30,
                              marginBottom: 30,
                        }}
                        name="dates"
                        layout={isMobile ? "vertical" : "inline"}
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                  >
                        <Form.Item
                              name="dates"
                              rules={[{ required: true, message: 'require' }]}
                        >
                              <RangePicker onChange={dateHandler} format="YYYY-MM-DD" />
                        </Form.Item>
                        <Form.Item >
                              <Button type="primary"
                                    htmlType="submit" icon={<SearchOutlined />}
                                    loading={loading}>
                                    Search
                              </Button>
                        </Form.Item>
                  </Form>
                  <Row>
                        <Col span={24} style={{
                              width: '100%',
                              overflowX: 'scroll',
                        }}>
                              <TransactionTable data={masterTransaction} style={{ marginTop: 20 }} />
                        </Col>
                  </Row>
            </>
      );
}

const styles = {
      boxWrapper: {
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            gap: 10
      },
      box: {
            display: 'flex',
            width: 320,
            height: 80,
            boxSizing: 'border-box',
            boxShadow: '1px 1px 5px 0px gray',
            marginRight: '10px',
            borderRadius: '5px',
            overflow: 'hidden'
      },
      icon: {
            width: '50%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: '3rem'
      },
      numWrapper: {
            width: '50%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'flex-start',
            color: 'white',
            padding: '10px'
      },
      masterIcon: {
            color: '#3f37c9',
      },
      master: {
            backgroundColor: '#3f37c9',
      },
      number: {
            fontSize: '1.3rem',
      },
      balanceIcon: {
            color: '#55a630'
      },
      balance: {
            backgroundColor: '#55a630'
      },
      downlineIcon: {
            color: '#FF6B6B'
      },
      downline: {
            backgroundColor: '#FF6B6B'
      }
}