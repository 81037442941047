import React from 'react';
import { connect } from 'react-redux';
import { Modal, Form, message, Input } from 'antd';
import * as actionTypes from '../../redux/actions/agent/actionTypes';
import { updateBasicInfo } from '../../redux/actions/agent/updateAgent';

const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 12 },
};

class UpdateBasicInfoModal extends React.Component {
    constructor(props) {
        super(props);
        this.form = React.createRef();
    }
    onFinish = async (values) => {
        await this.props.onUpdate({
            id:this.props.agent.id,
            name:values.name,
            mobile:values.mobile
        });
        if (this.props.error) {
            message.error(this.props.error);
        }
        if (this.props.message) {
            message.success(this.props.message);
        }
        this.form.current.resetFields();
        this.props.onClose();
    }
    onFinishFailed = (errors) => {
        console.log(errors)
    }
    handleCancel = () => {
        this.props.onClose();
    }
    render() {
        return (
            <Modal
                title="Update Basic Info"
                key={this.props.agent?.id}
                centered
                visible={this.props.visible}
                okButtonProps={{ form: 'update-bi-form', key: 'submit', htmlType: 'submit' }}
                confirmLoading={this.props.loading}
                onCancel={this.handleCancel}
                okText="Save"
                loading={this.props.loading}
            >
                <Form
                {...layout}
                    ref={this.form}
                    style={{ margin: 25 }}
                    id="update-bi-form"
                    name="basic"
                    initialValues={{
                        name:this.props.agent?.name,
                        mobile:this.props.agent?.mobile
                    }}
                    onFinish={this.onFinish}
                    onFinishFailed={this.onFinishFailed}
                >
                    <Form.Item
                        name="name"
                        label="Name"
                        rules={[
                            {
                                required: true,
                                message: 'Require!',
                            }
                        ]}
                        hasFeedback
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="mobile"
                        label="Mobile"
                        rules={[
                            {
                                required: true,
                                message: 'Require!',
                            }
                        ]}
                        hasFeedback
                    >
                        <Input />
                    </Form.Item>
                </Form>
            </Modal>
        );
    }
}

const mapStateToProps = state => ({
    visible: state.agent.updateBasicInfoVisible,
    loading: state.agent.loading,
    error: state.agent.error,
    message: state.agent.message,
    agent:state.agent.one
})

const mapDispatchToProps = dispatch => ({
    onUpdate: (info) => dispatch(updateBasicInfo(info)),
    onClose: () => dispatch({
        type:actionTypes.TOGGLE_BASIC_INFO_UPDATE
    }),
})

export default connect(mapStateToProps, mapDispatchToProps)(UpdateBasicInfoModal);