import { createStore, compose, applyMiddleware, combineReducers } from "redux";
import ReduxThunk from 'redux-thunk';
import authReducer from './reducers/authReducer';
import agentReducer from './reducers/agentReducer';
import transactionReducer from './reducers/transactionReducer';
import reportReducer from "./reducers/reportReducer";
import betPatternReducer from "./reducers/betPatternReducer";
import userReducer from "./reducers/userReducer";
import agreementReducer from './reducers/agreementReducer';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const rootReducer = combineReducers({
    auth: authReducer,
    agent:agentReducer,
    transaction:transactionReducer,
    report:reportReducer,
    betPattern:betPatternReducer,
    user:userReducer,
    agreement:agreementReducer
  });
  
  const store = createStore(
    rootReducer,
    composeEnhancers(applyMiddleware(ReduxThunk))
  );
  
  export default store;