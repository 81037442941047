import update from '../utilities/update';
import {loading} from './shared/loading';
import {error} from './shared/error';
import {
    CREATE_TRANSACTION_START,
    CREATE_TRANSACTION_SUCCESS,
    CREATE_TRANSACTION_FAIL,
    TOGGLE_TRANSACTION_ADD_MODAL,
    GET_MASTER_TRANSACTION_START,
    GET_MASTER_TRANSACTION_SUCCESS,
    GET_MASTER_TRANSACTION_FAIL,
    GET_M_TRANSACTION_DETAIL_START,
    GET_M_TRANSACTION_DETAIL_SUCCESS,
    GET_M_TRANSACTION_DETAIL_FAIL,
    GET_AGENT_TRANSACTION_START,
    GET_AGENT_TRANSACTION_SUCCESS,
    GET_AGENT_TRANSACTION_FAIL,
    GET_AGENT_TRANSACTION_DETAIL_START,
    GET_AGENT_TRANSACTION_DETAIL_SUCCESS,
    GET_AGENT_TRANSACTION_DETAIL_FAIL
} from '../actions/transaction/actionTypes';

const initialState={
    loading:false,
    error:null,
    message:null,
    all:[],
    masterTransaction:[],
    addVisible:false,
    mDetailTransaction:[],
    agentTransaction:[],
    agentDetailTransaction:[],
}

const createTransaction=(state,action)=>{
   console.log('response',action.payload);
    return update(state,{
        loading:false,
        message:'create transaction success',
    })
}

const toggleAddModal=(state,action)=>{
    return update(state,{
        addVisible:!state.addVisible
    })
}

const getMasterTransaction=(state,action)=>{
    return update(state,{
        loading:false,
        masterTransaction:action.payload
    });
}

const getMTransactionDetail=(state,action)=>{
    return update(state,{
        loading:false,
        mDetailTransaction:action.payload
    })
}

const getAgentTransaction=(state,action)=>{
    return update(state,{
        loading:false,
        agentTransaction:action.payload
    })
}

const getAgentTransactionDetail=(state,action)=>{
    return update(state,{
        loading:false,
        agentDetailTransaction:action.payload
    })
}

const transactionReducer=(state=initialState,action)=>{
    switch(action.type)
    {
        case CREATE_TRANSACTION_START:
            return loading(state,action);
        case CREATE_TRANSACTION_SUCCESS:
            return createTransaction(state,action);
        case CREATE_TRANSACTION_FAIL:
            return error(state,action);
        case TOGGLE_TRANSACTION_ADD_MODAL:
            return toggleAddModal(state,action);
        case GET_MASTER_TRANSACTION_START:
            return loading(state,action);
        case GET_MASTER_TRANSACTION_SUCCESS:
            return getMasterTransaction(state,action);
        case GET_MASTER_TRANSACTION_FAIL:
            return error(state,action);
        case GET_M_TRANSACTION_DETAIL_START:
            return loading(state,action);
        case GET_M_TRANSACTION_DETAIL_SUCCESS:
            return getMTransactionDetail(state,action);
        case GET_M_TRANSACTION_DETAIL_FAIL:
            return error(state,action);
        case GET_AGENT_TRANSACTION_START:
            return loading(state,action);
        case GET_AGENT_TRANSACTION_SUCCESS:
            return getAgentTransaction(state,action);
        case GET_AGENT_TRANSACTION_FAIL:
            return error(state,action);
        case GET_AGENT_TRANSACTION_DETAIL_START:
            return loading(state,action);
        case GET_AGENT_TRANSACTION_DETAIL_SUCCESS:
            return getAgentTransactionDetail(state,action);
        case GET_AGENT_TRANSACTION_DETAIL_FAIL:
            return error(state,action);
        default:
            return state;
    }
}

export default transactionReducer;